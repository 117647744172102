/* .box {
  display: block;
  margin: 5px auto;
  width: 30%;
} */

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 91vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/cart-background.jpeg");
  background-size: cover;
  background-position: center;
}

.message > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 50px 20px;
  background-color: #f0ab26;
  border: 5px solid rgba(255, 255, 255, 0.5);
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.message > div > h1 {
  font-size: 32px;
  margin: 0;
}

.message > div > p {
  font-size: 22px;
  margin: 0;
}

.message > * {
  color: #fff;
}

.message > div > img {
  width: 15%;
}
