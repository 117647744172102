@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/about-background.jpeg");
    background-size: cover;
    color: #fff;
}

.pageContainer > h1 {
    margin: 50px 0 10px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 55px;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: -1px;
}

.pageContainer svg {
    fill: #daa236;
    width: 60px;
    height: 60px;
    transition: fill 0.3s;
}

.pageContainer > svg.heartIcon:hover {
    fill: #e9373a;
}

.pageContainer > h3 {
    margin: 15px 0 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 44.2px;
    letter-spacing: -1px;
    text-align: center;
}

.ownerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 70%;
    margin: 0 0 30px 0;
}

.ownerContainer > img {
    width: 50%;
    border-radius: 20px;
    border: 3px solid #f0ab26;
}

.ownerContainer > p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 28px;
}

.team {
    margin-top: 30px;
    width: 60%;
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    line-height: 29px;
    text-align: center;
}

.rulesContainer {
    padding: 20px 50px;
    margin: 20px 0 50px;
    background-color: rgba(29, 29, 29, 0.7);
    box-shadow: 
        inset 5px 5px #f0ab26,
        inset -5px -5px #f0ab26;
    text-align: center;
}

.rulesContainer > p {
    margin-bottom: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.rulesContainer > .rule {
    margin-bottom: 30px;
}

.rulesContainer > .rule > h4 {
    margin: 0 0 5px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
}

.rulesContainer > .rule > p {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
}

.slogan {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0;
    margin-bottom: 50px;
}

/* .slogan > svg {
    width: 70px;
    height: 70px;
    fill: #f0ab26 ;
} */

.slogan > p {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
}

@media only screen and (min-width: 1369px) and (max-width: 1700px) {
    .ownerContainer {
        width: 80%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1368px) {
    .ownerContainer {
        width: 90%;
    }
}

@media only screen and (min-width: 541px) and (max-width: 1024px) {
    .ownerContainer {
        flex-direction: column;
        gap: 10px;
        width: 80%;
    }

    .ownerContainer > img {
        width: 80%;
    }

    .ownerContainer > p {
        font-size: 23px;
        line-height: 33px;
        text-align: center;
    }

    .team {
        width: 80%;
        font-size: 23px;
        line-height: 33px;
    }

    .rulesContainer {
        width: 80%;
        padding: 20px;
    }

    .rulesContainer > p {
        font-size: 23px;
        line-height: 33px;
    }

    .rulesContainer > .rule > h4 {
        font-size: 25px;
        line-height: 35px;
    }
    
    .rulesContainer > .rule > p {
        font-size: 23px;
        line-height: 33px;
    }

    .slogan {
        margin: 0 0 30px 0;
    }
    
    .slogan > p {
        width: 90%;
    }
}

@media only screen and (max-width: 540px) {
    .pageContainer > h1 {
        margin: 30px 0 10px;
        font-size: 32px;
        line-height: 42px;
    }

    .pageContainer svg {
        width: 55px;
        height: 55px;
    }

    .pageContainer > h3 {
        width: 70%;
        margin: 10px 0 30px;
        font-size: 25px;
        line-height: 35px;
    }

    .ownerContainer {
        flex-direction: column;
        gap: 20px;
        width: 90%;
    }

    .ownerContainer > img {
        width: 100%;
    }

    .ownerContainer > p {
        text-align: center;
        margin: 0 0 20px 0;
    }

    .team {
        margin-top: 30px;
        width: 90%;
        font-size: 19px;
        line-height: 29px;
        text-align: center;
    }

    .rulesContainer {
        width: 80%;
        padding: 10px 20px;
        margin: 20px 0;
    }

    .rulesContainer > .rule > h4 {
        margin: 0;
    }

    .slogan {
        margin: 0 0 20px 0;
    }
}