@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans:wght@300&display=swap');

.navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 25px 0;
    position: sticky;
    top: 0;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 700;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    z-index: 5;
}

.navbar a {
    color: #000;
    text-decoration: none;
    transition: color 0.3s;
}

.navbar > .logo {
    font-size: 19px;
}

.navbar > .logo > a:hover {
    color: #000;
    text-decoration: underline;
}

.navbar > .pages {
    display: flex;
    justify-content: space-between;
    /* gap: 40px; */
    width: 610px;
    /* background-color: aquamarine; */
}

.navbar > .pages > a {
    white-space: nowrap;
}

.navbar > .pages > a:hover {
    color: #daa236;
    cursor: pointer;
}

.navbar > .pages > a.active:not(:first-of-type) {
    color: #daa236;
}

.navbar > .pages > a.active:first-of-type:hover {
    color: #000;
}

.navbarButtons {
    display: flex;
    align-items: center;
    gap: 60px;
}

.navbar > .navbarButtons > .lang {
    display: flex;
    align-items: center;
}

.navbar > .navbarButtons > .lang > .langButton,
.navbar > .navbarButtons > .lang > .langButtonActive 
{
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    width: fit-content;
    align-self: flex-end;
    transition: color 0.3s;
}

.navbar > .navbarButtons > .lang > .langButton > span {
    padding: 0 1px;
    margin-left: -7px;
    color: transparent;
    border-right: 1px solid #000;
}

.navbar > .navbarButtons > .lang > .langButton > .langOption {
    position: relative;
    padding: 0 1px;
    transition: color 0.3s;
}

.navbar > .navbarButtons > .lang > .langButton > .langOption::after {
    position: absolute;
    content:'';
    display: block;
    width: 0;
    border-bottom: 2px solid transparent;
    transition: 0.3s;
}

.navbar > .navbarButtons > .lang > .langButton > .langOption:hover {
    color: #daa236;
    cursor: pointer;
}

.navbar > .navbarButtons > .lang > div > .langIconContainer > svg {
    width: 25px;
    height: 25px;
}

.navbar > .navbarButtons > .lang > .langButton > .langActive {
    position: relative;
    padding: 0 1px;
}

.navbar > .navbarButtons > .lang > .langButton > .langActive::after {
    position: absolute;
    left: 0;
    content:'';
    display: block;
    width: 100%;
    border-bottom: 2px solid #daa236;
    transition: 0.3s;
}

/* .navbar > .navbarButtons > .lang > .langButton:hover > .langIconContainer > svg,
.navbar > .navbarButtons > .lang > .langButtonActive > .langIconContainer > svg {
    fill: #daa236;
} */

/* .navbar > .navbarButtons > .lang > .langButtonActive {
    color: #daa236;
} */

/* .navbar > .navbarButtons > .lang > .dropdownContent {
    position: absolute;
    margin: 0 0 0 65px;
    padding: 0;
    list-style: none;
    text-align: center;
    cursor: pointer;
}

.navbar > .navbarButtons > .lang > .dropdownContent > li {
    display: inline;
    padding: 5px;
    border: 2px solid #fff;
    transition: color 0.3s,
    border 0.3s;
    text-transform: uppercase;
} */

/* .navbar > .lang > .dropdownContent > li:last-child {
    border-right: 1px solid #233447;
} */

/* .navbarButtons > .lang > .dropdownContent > li:hover {
    color: #daa236;
    border: 2px solid;
    border-color: #daa236;
}

.navbar > .navbarButtons > .lang > .hideDropdown {
    display: none;
} */

.navbar svg {
    width: 30px;
    height: 30px;
    transition: fill 0.3s;
}

.navbar > .navbarButtons > .cartButton,
.navbar > .navbarButtons > .activeCartButton {
    position: relative;
}

.navbar > .navbarButtons > .cartButton:hover,
.navbar > .navbarButtons > .activeCartButton > svg {
    fill: #daa236;
}

.navbar > .navbarButtons > .cartButton > svg,
.navbar > .navbarButtons > .activeCartButton > svg {
    width: 30px;
    height: 30px;
}

.navbar > .navbarButtons > .cartButton > .showCartCounter,
.navbar > .navbarButtons > .activeCartButton > .showCartCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #daa236;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
}

.navbar > .navbarButtons > .cartButton > .hideCartCounter,
.navbar > .navbarButtons > .activeCartButton > .hideCartCounter{
    display: none;
}

.hideSideMenu {
    display: none;
}

.burgerButton {
    display: none;
}

@media only screen and (min-width: 961px) and (max-width: 1368px) {
    .navbar > .pages {
        width: 530px;
    }

    .navbarButtons {
        gap: 10px;
    }

    .navbar > .navbarButtons > .lang > .langButton,
    .navbar > .navbarButtons > .lang > .langButtonActive {
        gap: 5px;
        width: fit-content;
    }

    .navbar > .navbarButtons > .lang > .langButton > span {
        padding: 0 1px;
        margin-left: -7px;
        color: transparent;
        border-right: 1px solid #000;
    }

    /* .navbar > .navbarButtons > .lang > .dropdownContent {
        margin: 0 0 0 60px;
    } */
}

@media only screen and (min-width: 541px) and (max-width: 960px) {
    .navbar svg {
        width: 35px;
        height: 35px;
    }
    
    .navbar > .logo {
        font-size: 30px;
    }

    .navbar > .pages {
        display: none;
    }

    .navbar > .navbarButtons {
        gap: 30px;
    }

    .navbar > .navbarButtons > .lang {
        display: none;
    }

    .navbar > .navbarButtons > .cartButton > svg,
    .navbar > .navbarButtons > .activeCartButton > svg {
        width: 40px;
        height: 40px;
    }

    .navbar > .navbarButtons > .cartButton > .showCartCounter,
    .navbar > .navbarButtons > .activeCartButton > .showCartCounter {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #daa236;
        color: #fff;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 10px;
    }

    .burgerButton {
        display: flex;
    }
    
    .burgerButtonActive {
        display: flex;
    }
    
    .burgerButtonActive > svg {
        fill: #daa236;
    }

    .sideMenuContent {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 75%;
        height: 100%;
        background-color: #233447;
    }
    
    .sideMenuContent > .lang {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        color: #fff;
    }
    
    .sideMenuContent > .lang > div > div > svg {
        width: 30px;
        height: 30px;
        fill: #fff;
    }
    
    .sideMenuContent > .lang > .langButton {
        position: absolute;
        top: 10px;
        right: 30px;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        font-size: 24px;
    }

    .sideMenuContent > .lang > .langButton > span {
        padding: 0;
        width: 0;
        color: transparent;
        border-right: 1px solid #fff;
    }
    
    .sideMenuContent > .lang > .langButton > .langOption {
        position: relative;
        padding: 0 1px;
        font-size: 20px;
        border-bottom: 2px solid transparent;
    }

    .sideMenuContent > .lang > .langButton > .langOption::after {
        position: absolute;
        content:'';
        display: block;
        width: 0;
        border-bottom: 2px solid transparent;
    }

    .sideMenuContent > .lang > .langButton > .langActive {
        position: relative;
        padding: 0 1px;
        font-size: 20px;
        border-bottom: 2px solid transparent;
    }

    .sideMenuContent > .lang > .langButton > .langActive::after {
        position: absolute;
        left: 0;
        content:'';
        display: block;
        width: 100%;
        border-bottom: 2px solid #daa236;
        transition: 0.3s;
    }
    
    /* .sideMenuContent > .lang > ul {
        position: absolute;
        margin: 0 0 0 -60px;
        padding: 0;
        list-style: none;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 24px;
    }
    
    .sideMenuContent > .lang > ul > li {
        display: inline;
        padding: 5px;
        text-transform: uppercase;
    } */
    
    .sideMenuContent > .sideMenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .sideMenuContent > div > a {
        font-family: 'Montserrat', sans-serif;
        letter-spacing: -1px;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        width: 100%;
        padding: 20px;
        border-top: 2px solid #00000014;
        background-color: #233447;
        box-sizing: border-box;
    }
    
    .sideMenuContent > div > a:last-of-type {
        border-bottom: 2px solid #00000014;
    }
}

@media only screen and (max-width: 540px) {
    .navbar > .pages {
        display: none;
    }

    .navbar > .navbarButtons {
        gap: 15px;
    }

    .navbar > .navbarButtons > .lang {
        display: none;
    }

    .burgerButton {
        display: flex;
    }
    
    .burgerButtonActive {
        display: flex;
    }
    
    .burgerButtonActive > svg {
        fill: #daa236;
    }

    .sideMenuContent {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 75%;
        height: 100%;
        background-color: #233447;
    }
    
    .sideMenuContent > .lang {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        color: #fff;
    }
    
    .sideMenuContent > .lang > div > div > svg {
        fill: #fff;
    }
    
    .sideMenuContent > .lang > .langButton {
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
    }

    .sideMenuContent > .lang > .langButton > span {
        padding: 0;
        width: 0;
        color: transparent;
        border-right: 1px solid #fff;
        background-color: lightcoral;
    }
    
    .sideMenuContent > .lang > .langButton > .langOption {
        position: relative;
        padding: 0 1px;
        font-size: 20px;
        border-bottom: 2px solid transparent;
    }

    .sideMenuContent > .lang > .langButton > .langOption::after {
        position: absolute;
        content:'';
        display: block;
        width: 0;
        border-bottom: 2px solid transparent;
    }

    .sideMenuContent > .lang > .langButton > .langActive {
        position: relative;
        padding: 0 1px;
        font-size: 20px;
        border-bottom: 2px solid transparent;
    }

    .sideMenuContent > .lang > .langButton > .langActive::after {
        position: absolute;
        left: 0;
        content:'';
        display: block;
        width: 100%;
        border-bottom: 2px solid #daa236;
        transition: 0.3s;
    }
    
    /* .sideMenuContent > .lang > ul {
        position: absolute;
        margin: 0 0 0 -50px;
        padding: 0;
        list-style: none;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .sideMenuContent > .lang > ul > li {
        display: inline;
        padding: 5px;
        text-transform: uppercase;
    } */
    
    .sideMenuContent > .sideMenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .sideMenuContent > div > a {
        font-family: 'Montserrat', sans-serif;
        letter-spacing: -1px;
        line-height: 54px;
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        width: 100%;
        padding: 10px;
        border-top: 1px solid #00000014;
        background-color: #233447;
        box-sizing: border-box;
    }
    
    .sideMenuContent > a:last-of-type {
        border-bottom: 1px solid #00000014;
    }
}