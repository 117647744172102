@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 91vh;
    margin: 0;
    padding: 50px 250px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/dishes-background.jpg") center;
    background-size: cover;
    box-sizing: border-box;
}

.header > h1 {
    margin: 0;
    padding: 0;
    font-family: 'Fira Sans', sans-serif;
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1px;
    width: 50%;
}

.header > .dishesHeading, .header > p {
    color: #fff;
}

.header > .brandHeading {
    color: #f0ab26;
}

.header > p {
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    text-align: center;
    font-weight: 400;
    line-height: 29px;
    padding: 0 100px;
    width: 70%;
}

.groupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f64747; */
}

.groupContainer > .slogan {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #daa236;
    margin-top: 70px;
    margin-bottom: -30px;
    text-transform: uppercase;
}

.groupContainer > svg {
    margin-top: 50px;
    fill: #daa236;
    width: 40px;
    height: 40px;
    transition: fill 0.3s;
}

.groupContainer > svg.heartIcon:hover {
    fill: #e9373a;
}

.groupContainer > svg.broccoliIcon:hover {
    fill: #1fcf1f;
}

.groupContainer > svg.fridgeIcon:hover {
    fill: #383ba3;
}

.groupContainer > svg.dessertIcon:hover {
    fill: #ff4775;
}

.groupContainer > .groupTitle {
    font-family: 'Fira Sans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 52px;
    color: #233447;
    margin: 10px 0 35px;
    text-transform: uppercase;
    text-align: center;
}

.firstCourses,
.salads,
.frozenProducts,
.desserts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    row-gap: 30px;
    margin: 0 auto;
    padding-bottom: 70px;
    width: 60%;
    border-bottom: 2px solid #848b93bf;
    /* background-color: lightskyblue; */
}

.desserts {
    border-bottom: none;
    padding-bottom: 50px;
}

@media only screen and (min-width: 1369px) and (max-width: 1700px) {
    .header > h1 {
        width: 70%;
    }

    .firstCourses,
    .salads,
    .frozenProducts,
    .desserts {
        width: 70%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1368px) {
    .header > h1 {
        font-size: 50px;
        line-height: 60px;
        padding: 0 20px;
        width: 70%;
    }

    .header > p {
        width: 100%;
    }
    
    .firstCourses,
    .salads,
    .frozenProducts,
    .desserts {
        width: 90%;
    }
}

@media only screen and (min-width: 961px) and (max-width: 1024px){
    .header {
        padding: 0;
    }

    .header > h1 {
        font-size: 40px;
        line-height: 50px;
        padding: 0 20px;
        width: 60%;
    }

    .header > p {
        width: 70%;
    }
    
    .firstCourses,
    .salads,
    .frozenProducts,
    .desserts {
        width: 100%;
    }
}

@media only screen and (min-width: 541px) and (max-width: 960px){
    .header {
        padding: 0;
    }

    .header > h1 {
        font-size: 50px;
        line-height: 60px;
        padding: 0 20px;
        width: 80%;
    }

    .header > p {
        width: 70%;
        font-size: 25px;
        line-height: 35px;
    }

    .groupContainer > .slogan {
        font-size: 23px;
    }

    .firstCourses,
    .salads,
    .frozenProducts,
    .desserts {
        width: 90%;
    }
}

@media only screen and (max-width: 540px) {
    .header {
        width: 100%;
        padding: 50px 0;
        background-position: 100%;
    }

    .header > h1 {
        font-size: 32px;
        line-height: 42px;
        padding: 0 20px;
        width: 80%;
    }

    .header > p {
        width: 80%;
        padding: 0;
    }

    .firstCourses,
    .salads,
    .frozenProducts,
    .desserts {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }
}