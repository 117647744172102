@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.dish {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 32%;
    background-color: #f6f6f6;
}

.dish > .dishTitle {
    margin: 25px 0 0;
    padding: 0 40px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
}

.dish > .dishDescription {
    padding: 0 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 27.2px;
    color: #233447;
    white-space: pre-wrap;
    text-align: center;
}

.dish > .priceOrderContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.dish > .priceOrderContainer > .dishPriceContainer {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 27.2px;
    text-align: center;
    width: 80%;
    padding: 10px 0;
    border-top: 2px solid #848b93bf;
    border-bottom: 2px solid #848b93bf;
}

.dish > .priceOrderContainer > .dishPriceContainer > .dishPrice {
    color: #daa236;
}

.dish > .priceOrderContainer > .orderButton {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 29px 7px 32px;
    margin: 10px 0 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #233447;
    border: 2px solid #233447;
    background-color: transparent;
    cursor: pointer;
    transition: 
        background 0.3s,
        color 0.3s;
}

.dish > .priceOrderContainer > .orderButton:hover {
    background-color: #233447;
    color: #fff;
}

.dish > .priceOrderContainer > .orderButton > svg {
    width: 25px;
    height: 25px;
    fill: #233447;
    transition: fill 0.3s;
    /* border: 1px solid #000; */
}

.dish > .priceOrderContainer > .orderButton:hover > svg {
    fill: #fff;
}

.dish > .priceOrderContainer > .pressedButton {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 29px 7px 32px;
    margin: 10px 0 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fff;
    border: 2px solid #daa236;
    background-color: #daa236;
    transition: 
        background 0.3s,
        color 0.3s;
}

.dish > .priceOrderContainer > .pressedButton > svg {
    width: 25px;
    height: 25px;
    fill: #fff;
    /* border: 1px solid #000; */
}

@media only screen and (min-width: 961px) and (max-width: 1700px){
    .dish {
        width: 30%;
    }

    .dish > .dishDescription {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 960px){
    .dish {
        width: 45%;
    }

    .dish > .dishDescription {
        width: 80%;
        font-size: 20px;
        line-height: 30px;
    }

    .dish > .priceOrderContainer > .dishPriceContainer {
        font-size: 18px;
        line-height: 28px;
    }

    .dish > .priceOrderContainer > .orderButton {
        font-size: 15px;
        line-height: 25px;
        transition:
            background 0.1s,
            color 0.1s;
    }

    .dish > .priceOrderContainer > .orderButton:hover {
        background-color: unset;
        color: unset;
    }

    .dish > .priceOrderContainer > .orderButton:active {
        background-color: #daa236;
        color: #fff;
    }
}

@media only screen and (max-width: 767px) {
    .dish {
        width: 90%;
    }

    .dish > .priceOrderContainer > .orderButton {
        transition:
            background 0.1s,
            color 0.1s;
    }

    .dish > .priceOrderContainer > .orderButton:hover {
        background-color: unset;
        color: unset;
    }

    .dish > .priceOrderContainer > .orderButton:active {
        background-color: #daa236;
        border-color: #daa236;
        color: #fff;
    }
}