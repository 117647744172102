@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.textContainer {
    width: 65%;
    padding: 50px 0;
    margin: 0 auto;
}

.privacyContainer > .textContainer > h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 48px;
    text-align: center;
    margin: 0;
}

.privacyContainer > .textContainer > p {
    white-space: pre-wrap;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 50px;
}

.privacyContainer > .textContainer > p:last-of-type {
    margin-bottom: 0;
}

@media only screen and (max-width: 920px) {
    .textContainer {
        width: 80%;
        padding: 50px 0;
    }

    .privacyContainer > .textContainer > h2 {
        font-size: 26px;
        line-height: 36px;
    }

    .privacyContainer > .textContainer > p {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 30px;
    }
}