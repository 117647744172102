@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.contactsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    min-height: 91vh;
    padding: 50px 0;
    box-sizing: border-box;
}

.leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
}

.leftBlock > p {
    margin: 5px;
}

.leftBlock > .adress {
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    line-height: 29px;
    font-weight: 700;
}

.leftBlock > iframe {
    border: none;
    margin-top: 20px;
    width: 555px;
    height: 454px;
}

.rightBlock {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 30px;
    width: 30%;
    height: 100%;
    /* background-color: lightpink; */
}

.rightBlock > h2 {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 42px;
    line-height: 52px;
    font-weight: 700;
    color: #233447;
}

.rightBlock > input {
    width: 50%;
    height: 35px;
    outline: none;
    border: 1px solid #23344733;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #233447;
}

.rightBlock > input::placeholder, .rightBlock > textarea::placeholder {
    font-weight: 400;
    color: rgba(35, 52, 71, 0.6);
}

.rightBlock > textarea {
    /* width: 483px; */
    height: 118px;
    padding: 20px;
    outline: none;
    border: 1px solid #23344733;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #233447;
    resize: none;
}

.rightBlock > input:focus,
.rightBlock > textarea:focus {
    border-color: #daa236;
}

.rightBlock > button {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    padding: 14px 32px 14px 35px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 23px;
    color: white;
    background-color: #233447;
    border: none;
}

.rightBlock > button > svg {
    width: 16px;
    fill: #fff;
}

.rightBlock > button > span {
    padding: 0;
}

.rightBlock > button {
    transition: background 0.3s;
}

.rightBlock > button:hover {
    background-color: #f0ab26;
    cursor: pointer;
}

.loadingStyle {
    padding: 20px 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
}

.errorStyle {
    padding: 20px 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
    color: #fff;
    background-color: rgba(255, 0, 0, 0.5);
}

.messageStyle {
    padding: 20px 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
    color: #fff;
    background-color: #daa236;
}

@media only screen and (max-width: 1400px) {
    .rightBlock > input {
        width: 60%;
    }
    
}

@media only screen and (min-width: 921px) and (max-width: 1180px) {
    .leftBlock {
        width: 40%;
    }

    .leftBlock > iframe {
        width: 100%;
    }

    .rightBlock {
        width: 40%;
    }

    .rightBlock > textarea {
        width: 100%;
        box-sizing: border-box;
    }
}

@media only screen and (min-width: 541px) and (max-width: 920px) {
    .contactsContainer {
        flex-direction: column;
        width: 100%;
        padding: 50px 0;
    }

    .leftBlock {
        width: 80%;
    }

    .leftBlock > iframe {
        width: 100%;
    }

    .rightBlock {
        width: 80%;
        align-items: center;
    }

    .rightBlock > input {
        width: 100%;
        box-sizing: border-box;
    }

    .rightBlock > textarea {
        width: 100%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 540px) {
    .contactsContainer {
        flex-direction: column;
        width: 100%;
        padding: 50px 0;
    }

    .leftBlock {
        width: 90%;
    }

    .leftBlock > iframe {
        width: 100%;
    }

    .rightBlock {
        width: 90%;
        height: 100%;
        gap: 20px;
        align-items: center;
    }

    .rightBlock > input {
        width: 100%;
        box-sizing: border-box;
    }

    .rightBlock > textarea {
        width: 100%;
        box-sizing: border-box;
    }
}