.button {
    position: fixed;
    bottom: 20px;
    right: 25px;
}

.button > svg {
    cursor: pointer;
    transition: 
        transform 0.3s,
        fill 0.3s;
}

.button > svg:hover {
    fill: #daa236;
    transform: translateY(-5px);
}

.hideButton {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .button > svg {
        transition: fill 0s;
        fill: #daa236;
    }
    
    .button > svg:hover {
        transform: unset;
    }
}