.reportContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 50px 350px;
}

.reportContainer > h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 48px;
    text-align: center;
    margin: 0;
}

@media only screen and (max-width: 768px) {
    .reportContainer {
        width: 85%;
        padding: 30px 0;
        margin: 0 auto;
        gap: 20px;
    }

    .reportContainer > h2 {
        font-size: 26px;
        line-height: 36px;
    }

    .reportContainer > img {
        width: 100%;
    }
}