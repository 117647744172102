.textContainer {
    width: 65%;
    padding: 50px 0;
    margin: 0 auto;
}

.cookiesContainer > .textContainer > h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 48px;
    text-align: center;
    margin: 0;
}

.cookiesContainer > .textContainer > p,
.cookiesContainer > .textContainer > .links > a {
    white-space: pre-wrap;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 35px;
}

.cookiesContainer > .textContainer > .links > a {
    font-weight: 700;
    color: #000;
}

.textContainer > .links {
    text-align: center;
}

@media only screen and (max-width: 920px) {
    .textContainer {
        width: 80%;
    }

    .cookiesContainer > .textContainer > h2 {
        font-size: 26px;
        line-height: 36px;
    }

    .cookiesContainer > .textContainer > p {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .cookiesContainer > .textContainer > .links > a {
        font-size: 16px;
        line-height: 25px;
    }
}