.cartContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 91vh;
    padding: 50px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/cart-background.jpeg");
    background-size: 100%;
    box-sizing: border-box;
}

.cartContainer > .cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
    width: 50%;
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 3px solid #daa236;
}

.cartContainer > .hideCart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
    width: 800px;
    padding: 50px;
    border: 3px solid #daa236;
    background-color: crimson;
}

.dishesContainer {
    width: 100%;
}

.dishesContainer > .dish {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid rgba(35, 52, 71, 0.3);
}

.dishesContainer > .dish > .dishIconContainer {
    margin: 0 0 0 15px;
}

.dishesContainer > .dish > .dishIconContainer > svg {
    width: 30px;
    height: 30px;
    fill: #233447;
    cursor: pointer;
    transition: fill 0.3s;
}

.dishesContainer > .dish > .dishIconContainer > svg:hover {
    fill: #daa236;
}

.dishesContainer > .dish > div {
    display: flex;
    gap: 20px;
}

.dishesContainer > .dish > .dishTitle,
.dishesContainer > .dish > div > .counterContainer  > .dishCounter,
.dishesContainer > .dish > div > .dishPrice {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #233447;
}

.dishesContainer > .dish > .dishTitle {
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    /* background-color: darksalmon; */
}

.dishesContainer > .dish > div > .counterContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dishesContainer > .dish > div > .counterContainer  > .dishCounter {
    width: 25px;
    text-align: center;
}

.dishesContainer > .dish > div > .dishPrice {
    width: 100px;
    text-align: center;
}

.dishesContainer > .dish > div > .dishPrice > span{
    font-size: 20px;
    color: #daa236;
}

.dishesContainer > .dish > div > .counterContainer > button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    color: #233447;
    border: 2px solid #233447;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    transition: 
        color 0.3s,
        background 0.3s,
        border 0.3s;
}

.dishesContainer > .dish > div > .counterContainer > button:hover {
    color: rgba(255, 255, 255, 0.9);
    background-color: #daa236;
    border-color: #daa236;
}

.cartContainer .finalSum {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #233447;
}

.cartContainer .finalSum > span {
    color: #233447;
    border-bottom: 2px solid #233447;
}

.cartContainer .finalSum > span > span {
    font-size: 32px;
    color: #daa236;
}

.shippingInfo {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #233447;
    text-align: center;
}

.cart > .userInfo {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #233447;
}

.cart > input {
    width: 50%;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #233447;
    text-align: center;
    background-color: transparent;
    border: 1px solid #233447;
}

input:focus {
    border-color: #daa236;
}

.cart > input::placeholder {
    font-weight: 400;
    color: #23344799;
}

.cartContainer .orderButton {
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    letter-spacing: 3px;
    color: #fff;
    border: none;
    padding: 14px 32px 14px 35px;
    background-color: #233447;
    cursor: pointer;
    transition: background 0.3s;
}

.cartContainer .orderButton:disabled {
    background-color: #4f5052;
    cursor: default;
}

.cartContainer .orderButton:not(:disabled):hover {
    background-color: #daa236;
    border-color: #daa236;
    color: #fff;
}

.loadingStyle {
    padding: 20px 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
}

.errorStyle {
    padding: 20px 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
    color: #fff;
    background-color: rgba(255, 0, 0, 0.5);
}

.cartContainer .clearButton > svg {
    width: 25px;
    height: 25px;
    fill: #233447;
}

.cartContainer .clearButton {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    /* padding: 11px 22px 11px 23px; */
    background-color: transparent;
    cursor: pointer;
}

.cartContainer .clearButton > span {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #233447;
    border-bottom: 2px solid transparent;
    transition: border 0.3s;
}

.cartContainer .clearButton:hover > span {
    /* background-color: #233447; */
    /* color: #fff; */
    border-bottom: 2px solid #daa236;
    /* text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-skip-ink: none; */
}

.cartContainer .clearButtonHide {
    display: none;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .cartContainer > .cart {
        width: 100%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 768px) {
    .cartContainer {
        padding: 20px 10px;
    }

    .cartContainer > .cart {
        width: 100%;
        padding: 50px 20px;
        box-sizing: border-box;
    }
    
    .cartContainer > .cart > .dishesContainer > .dish {
        flex-direction: column;
        justify-content: center;
        gap: 0;
        padding: 0 5px;
        box-sizing: border-box;
    }
    
    .cartContainer > .cart > .dishesContainer > .dish > .dishIconContainer {
        align-self: flex-start;
        margin: 15px 0 0 0;
        /* background-color: #daa236; */
    }
    
    .cartContainer > .cart > .dishesContainer > .dish > .dishTitle {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .dishesContainer > .dish > div > .counterContainer > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        background-color: #fff;
        color: #233447;
        border: 2px solid #233447;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 700;
    }

    .cart > .userInfo {
        text-align: center;
    }
    
    .cart > input {
        width: 100%;
        height: 35px;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        color: #233447;
        text-align: center;
        border: 1px solid #233447;
    }
}