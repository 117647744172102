.messengerIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}

.messengerIconContainer > svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.messengerIconContainer > svg:hover > path {
    transition: fill 0.3s;
}

.messengerIconContainer > svg:hover > path:first-of-type {
    fill: #f0ab26;
}

@media only screen and (min-width: 541px) and (max-width: 960px) {
    .messengerIconContainer {
        width: 40px;
        height: 40px;
    }

    .messengerIconContainer > svg {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}