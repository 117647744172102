@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 91vh;
    margin: 0;
    padding: 50px 250px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/menus-background.jpg");
    background-size: cover;
    box-sizing: border-box;
}

.header > h1 {
    width: 60%;
    margin: 0;
    padding: 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    line-height: 80px;
    color: #fff;
}

.header > h1 > span {
    color: #f0ab26;
}

.header > p {
    /* margin-bottom: 150px; */
    width: 60%;
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    text-align: center;
    line-height: 29px;
    color: #fff;
}

.groupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f64747; */
}

.groupContainer > .slogan {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #daa236;
    margin-top: 70px;
    margin-bottom: -30px;
    text-transform: uppercase;
}

.groupContainer > svg {
    margin-top: 50px;
    transition: fill 0.3s;
}

.groupContainer > svg.heartIcon:hover {
    fill: #e9373a;
}

.groupContainer > .groupTitle {
    font-family: 'Fira Sans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 52px;
    color: #233447;
    margin: 10px 0 35px;
    text-align: center;
    text-transform: uppercase;
}

.menus {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    row-gap: 30px;
    margin: 0 auto;
    padding-bottom: 50px;
    width: 60%;
}

.additionalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    padding: 50px;
    margin: 0 auto 50px;
    background-color: #f6f6f6;
}

.additionalContainer > h2 {
    margin: 0 0 30px 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 52px;
    text-align: center;
    color: #233447;
}

.additionalDishes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
}

.additionalDishes > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    padding: 15px;
    text-align: center;
    color: #233447;
    border-bottom: 2px solid #f0ab26;
}

@media only screen and (min-width: 1369px) and (max-width: 1700px) {
    .header > h1 {
        width: 80%;
    }

    .menus {
        width: 70%;
    }

    .additionalContainer {
        width: 70%;
        padding: 40px 20px;
    }

    .additionalContainer > h2 {
        margin: 0 0 20px 0;
    }

    .additionalDishes {
        padding: 0 30px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1368px) {
    .header > h1 {
        font-size: 50px;
        line-height: 60px;
        padding: 0 20px;
        width: 80%;
    }

    .header > p {
        width: 100%;
    }
    
    .menus {
        width: 90%;
    }

    .additionalContainer {
        width: 80%;
        padding: 40px 20px;
    }

    .additionalContainer > h2 {
        margin: 0 0 20px 0;
    }

    .additionalDishes {
        padding: 0 30px;
    }

    .additionalDishes > div {
        width: 45%;
    }
}

@media only screen and (min-width: 961px) and (max-width: 1024px){
    .header {
        padding: 0;
    }

    .header > h1 {
        font-size: 40px;
        line-height: 50px;
        padding: 0 20px;
        width: 50%;
    }

    .header > p {
        width: 60%;
    }
    
    .menus {
        width: 100%;
    }

    .additionalContainer {
        width: 90%;
        padding: 40px 20px;
    }

    .additionalContainer > h2 {
        margin: 0 0 20px 0;
    }

    .additionalDishes {
        padding: 0 20px;
    }

    .additionalDishes > div {
        width: 45%;
    }
}

@media only screen and (min-width: 541px) and (max-width: 960px){
    .header {
        padding: 0;
    }

    .header > h1 {
        font-size: 50px;
        line-height: 60px;
        padding: 0 20px;
        width: 80%;
    }

    .header > p {
        width: 70%;
        font-size: 25px;
        line-height: 35px;
    }

    .groupContainer > .slogan {
        font-size: 23px;
    }

    .menus {
        width: 90%;
    }

    .additionalContainer {
        width: 80%;
        padding: 40px 10px;
    }

    .additionalContainer > h2 {
        margin: 0 0 10px 0;
    }

    .additionalDishes {
        padding: 0 50px;
    }

    .additionalDishes > div {
        width: 100%;
    }
}

@media only screen and (max-width: 540px) {
    .header {
        width: 100%;
        padding: 0;
    }

    .header > h1 {
        font-size: 32px;
        line-height: 42px;
        padding: 0 20px;
        width: 70%;
    }

    .header > p {
        width: 70%;
        padding: 0;
    }

    .menus {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .additionalContainer {
        width: 90%;
        padding: 30px 0;
    }

    .additionalContainer > h2 {
        padding: 10px;
        margin: 0 0 10px 0;
    }

    .additionalDishes {
        padding: 0 20px;
    }

    .additionalDishes > div {
        width: 100%;
        padding: 10px 5px;
    }
}