@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 91vh;
    margin: 0;
    padding: 50px 250px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/main-background-0.jpg");
    background-size: cover;
    box-sizing: border-box;
}

.header > h1 {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 700;
    text-align: left;
    letter-spacing: -1px;
    line-height: 70px;
    color: #fff;
}

.header > h1 > span {
    color: #f0ab26;
}

.header > p {
    width: 50%;
    margin-bottom: 45px;
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    line-height: 29px;
    text-align: left;
    color: #fff;
}

.header > a {
    padding: 14px 42px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 25px;
    text-decoration: none;
    color: #fff;
    background-color: #f0ab26;
    cursor: pointer;
    text-transform: uppercase;
    transition: background 0.3s;
}

.header > a:hover {
    background-color: #c98400;
}

.dishesGroupsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    background-color: #daa236e6;
}

.dishesGroupsContainer > div {
    display: flex;
    justify-content: center;
    width: 100%;
    border: 1px solid #e9c786;
}

.dishesGroupsContainer > div > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 265px;
    height: 100px;
    border-left: 1px solid #e9c786;
    font-family: 'Fira Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 25px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    transition: background 0.3s;
}

.dishesGroupsContainer > div > a:last-of-type {
    border-right: 1px solid #e9c786;
}

.dishesGroupsContainer > div > a:hover {
    background-color: #f0ab26;
}

.dishesGroupsContainer > div > a > svg {
    fill: #fff;
    width: 24px;
    height: 24px;
}

.ourAim {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 91vh;
    margin: 0;
    padding: 50px 250px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/main-background-1.jpg") center;
    background-size: cover;
    box-sizing: border-box;
}

.ourAim > h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 52px;
    color: #fff;
}

.ourAim > div {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin: 0 0 20px 55px;
}

.ourAim > div > .iconContainer > svg {
    width: 40px;
    fill: #daa236;
    transition: fill 0.3s;
}

.ourAim > div > .iconContainer > svg:hover {
    fill: #fff;
}

.ourAim > div > div > h3 {
    margin: 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 34px;
    color: #fff;
}

.ourAim > div > div > p {
    width: 50%;
    padding: 20px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
}

.ordersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-bottom: 50px;
}

.ordersContainer > h1 {
    width: fit-content;
    padding: 20px 200px 20px;
    margin: 40px 0 0 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 80px;
    text-align: center;
    color: #233447;
}

.ordersBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 50px 10px;
    border-top: 1px solid #848b9326;
    border-bottom: 1px solid #848b9326;
}

.popularOrders {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 600px;
}

.popularOrders > div {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 40px;
}

.popularOrders > div > .picContainer > img {
    border-radius: 50%;
    width: 172px;
}

.popularOrders > div > .popularOrdersInfo {
    display: flex;
    flex-direction: column;
}

.popularOrders > div > .popularOrdersInfo > h4 {
    margin: 0 0 10px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 31px;
    text-transform: uppercase;
}

.popularOrders > div > .popularOrdersInfo > p {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 28px;
}

.makeOrder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: fit-content;
    padding: 50px 20px;
    background-color: #1d1d1d;
    box-shadow: 
        inset 5px 5px #f0ab26,
        inset -5px -5px #f0ab26;
}

.makeOrder > .iconContainer > svg {
    width: 50px;
    height: 50px;
    fill: #f0ab26 ;
    margin-bottom: 20px;
}

.makeOrder > .makeOrderHeading {
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 29px;
    color: #fff;
    text-transform: uppercase;
}

.makeOrder > .makeOrderCall {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #f0ab26;
}

.makeOrder > .makeOrderNumber {
    margin: 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 29px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 39px;
    color: #fff;
    text-decoration: none;
}

.makeOrder > .makeOrderNumber:hover {
    text-decoration: underline;
}

.makeOrder > .makeOrderForm {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 27.2px;
    text-align: center;
    color: #f0ab26;
}

.makeOrder > button {
    padding: 10px 25px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 23.4px;
    text-align: center;
    color: #1d1d1d;
    background-color: #f0ab26;
    border: none;
    cursor: pointer;
}

.makeOrder > button:hover {
    color: #fff;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 65vh;
    margin: 0;
    padding: 50px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/main-background-2.jpg");
    background-size: cover;
    box-sizing: border-box;
}

.about > h2 {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 52px;
    color: #fff;
}

.about > p {
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    width: 50%;
}

.about > a {
    padding: 14px 42px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 25px;
    text-decoration: none;
    color: #fff;
    background-color: #f0ab26;
    cursor: pointer;
    text-transform: uppercase;
    transition: background 0.3s;
}

.about > a:hover {
    background-color: #c98400;
}

@media only screen and (min-width: 1025px) and (max-width: 1368px) {
    .header {
        padding: 0 100px;
        background-position: center;
    }

    .header > h1 {
        font-size: 50px;
        line-height: 60px;
    }

    .header > p {
        width: 50%;
    }

    .ourAim {
        padding: 0 150px;
    }
    
    .ourAim > div > div > p {
        width: 60%;
        padding: 20px;
    }
}

@media only screen and (min-width: 921px) and (max-width: 1024px) {
    .header {
        padding: 0 100px;
        background-position: center;
    }

    .header > h1 {
        font-size: 40px;
        line-height: 50px;
        text-align: center;
    }

    .header > p {
        width: 80%;
    }

    .header > a {
        padding: 11px 26px;
    }

    .ourAim {
        padding: 30px;
    }
    
    .ourAim > div > div > p {
        width: 80%;
        padding: 20px;
    }

    .ordersBlock {
        flex-direction: column;
    }
    
    .about > a {
        padding: 11px 26px;
    }
}

@media only screen and (min-width: 541px) and (max-width:920px) {
    .header {
        align-items: center;
        padding: 0 100px;
        background-position: center;
    }

    .header > h1 {
        font-size: 50px;
        line-height: 60px;
        text-align: center;
    }

    .header > p {
        width: 100%;
        text-align: center;
        font-size: 25px;
        line-height: 35px;
    }

    .header > a {
        font-size: 20px;
        line-height: 30px;
    }

    .dishesGroupsContainer {
        padding: 30px 0;
    }

    .dishesGroupsContainer > div {
        flex-wrap: wrap;
    }
    
    .dishesGroupsContainer > div,
    .dishesGroupsContainer > div > a,
    .dishesGroupsContainer > div > a:last-of-type {
        border: none;
    }

    .dishesGroupsContainer > div > a {
        font-size: 20px;
        line-height: 30px;
    }

    .dishesGroupsContainer > div > a > svg {
        fill: #fff;
        width: 30px;
        height: 30px;
    }

    .ourAim {
        padding: 30px;
    }

    .ourAim > div > div > h3 {
        font-size: 31px;
        line-height: 41px;
    }
    
    .ourAim > div > div > p {
        width: 80%;
        padding: 20px;
        font-size: 23px;
        line-height: 33px;
    }

    .ordersContainer > h1 {
        font-size: 50px;
        line-height: 70px;
    }

    .ordersBlock {
        flex-direction: column;
    }

    .popularOrders > div > .picContainer > img {
        width: 200px;
    }

    .popularOrders > div > .popularOrdersInfo > h4 {
        font-size: 25px;
        line-height: 35px;
    }
    
    .popularOrders > div > .popularOrdersInfo > p {
        font-size: 23px;
        line-height: 33px;
    }

    .makeOrder {
        padding: 50px;
    }

    .makeOrder > .makeOrderHeading {
        font-size: 23px;
        line-height: 33px;
    }

    .makeOrder > .makeOrderCall {
        font-size: 25px;
        line-height: 35px;
        text-align: center;
    }
    
    .makeOrder > .makeOrderNumber {
        font-size: 35px;
        line-height: 45px;
    }

    .about {
        padding: 50px;
    }

    .about > h2 {
        font-size: 42px;
        line-height: 52px;
    }
    
    .about > p {
        width: 90%;
        font-size: 23px;
        line-height: 33px;
    }
    
    .about > a {
        padding: 11px 26px;
        font-size: 20px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 540px) {
    .header {
        align-items: center;
        padding: 0;
        background-position: center;
    }

    .header > h1 {
        padding: 0 20px;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
    }

    .header > p {
        width: 90%;
        margin-bottom: 35px;
        text-align: center;
    }

    .header > a {
        padding: 11px 26px;
    }

    .dishesGroupsContainer > div {
        flex-wrap: wrap;
        width: 200px;
        padding: 10px 0;
        border-top: none;
        border-left: 1px solid #e9c786;
        border-bottom: none;
        border-right: 1px solid #e9c786;
    }

    .dishesGroupsContainer > div > a {
        border-top: 1px solid #e9c786;
        border-left: none;
    }

    .dishesGroupsContainer > div > a:last-of-type {
        border-top: 1px solid #e9c786;
        border-right: none;
        border-bottom: 1px solid #e9c786;
    }

    .ourAim {
        padding: 30px;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/main-background-1-mobile.jpg") center;
        background-size: cover;
    }

    .ourAim > h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .ourAim > div {
        margin: 0 0 20px 10px;
    }
    
    .ourAim > div > div > p {
        width: 100%;
        padding: 10px 0;
    }

    .ordersContainer > h1 {
        padding: 20px 0;
        margin: 20px 0 0 0;
        font-size: 50px;
        line-height: 60px;
    }

    .ordersBlock {
        flex-direction: column;
    }

    .popularOrders {
        width: 90%;
    }

    .popularOrders > div {
        flex-direction: column;
    }

    .popularOrders > div > .picContainer {
        text-align: center;
    }

    .popularOrders > div > .picContainer > img {
        border-radius: 30%;
        width: 70%;
    }

    .popularOrders > div > .popularOrdersInfo {
        align-items: center;
    }

    .popularOrders > div > .popularOrdersInfo > p {
        text-align: center;
    }

    .makeOrder {
        width: fit-content;
        padding: 50px;
    }

    .makeOrder > .makeOrderCall{
        text-align: center;
    }

    .about {
        padding: 50px;
    }

    .about > h2 {
        font-size: 32px;
        line-height: 42px;
    }
    
    .about > p {
        width: 100%;
    }
    
    .about > a {
        padding: 11px 26px;
    }

    .reviewsSection > .review > p {
        width: 80%;
    }
}
