@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap');

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contactsInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 160px;
    gap: 130px;
    background-color: #313233;
}

.contactsInfo > div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.contactsInfo > div > svg{
    transition: fill 0.3s;
}

.contactsInfo > div > svg:hover {
    fill: #fff;
}

.contactsInfo > div > p {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.8px;
}

.contactsInfo > div > p > a {
    color: #f0ab26;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 25.2px;
    text-decoration: none;
}

.contactsInfo > div > p > a {
    font-size: 16px;
    line-height: 27.2px;
}

.contactsInfo > div > p > a:hover {
    text-decoration: underline;
}

.technicalInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0 10px;
    align-items: center;
    height: 212px;
}

.technicalInfo > .technicalLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    width: 100%;
}

.technicalInfo > .technicalLinks > div > a {
    color: #000;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 25.5px;
}

.technicalInfo > .technicalLinks > div > a:hover {
    text-decoration: underline;
}

.technicalInfo > .technicalLinks > div > span {
    cursor: default;
}

.copyright {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 27.2px;
    margin-top: 0;
}

@media only screen and (min-width: 961px) and (max-width: 1368px) {
    .contactsInfo {
        gap: 50px;
    }

    .technicalInfo > .technicalLinks {
        gap: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
    .contactsInfo {
        flex-direction: column;
        gap: 30px;
        height: fit-content;
        padding: 30px 0;
    }

    .contactsInfo > div {
        width: 500px;
        justify-content: center;
    }

    .contactsInfo > div > p {
        width: 100%;
        font-size: 21px;
        line-height: 31px;
    }

    .contactsInfo > div > p > a {
        font-size: 21px;
        line-height: 31px;
    }

    .technicalInfo {
        height: 100%;
        padding: 10px 0;
    }

    .technicalInfo > .technicalLinks {
        flex-direction: column;
        gap: 0;
    }

    .technicalInfo > .technicalLinks > .technicalPages {
        margin: 0 0 20px 0;
        padding: 0;
    }

    .technicalInfo > .technicalLinks > .technicalPages > a {
        font-size: 21px;
        line-height: 31px;
    }

    .copyright {
        margin: 10px 0 0;
        font-size: 17px;
        line-height: 27px;
    }
}

@media only screen and (max-width: 767px) {
    .contactsInfo {
        flex-direction: column;
        gap: 30px;
        height: fit-content;
        padding: 30px 0;
    }

    .contactsInfo > div {
        width: 300px;
        /* background-color: cadetblue; */
    }

    .contactsInfo > div > p {
        width: 100%;
        font-size: 21px;
        line-height: 31px;
    }

    .contactsInfo > div > p > a {
        font-size: 21px;
        line-height: 31px;
    }

    .technicalInfo {
        height: 100%;
        padding: 10px 0;
        /* background-color:#f0ab26; */
    }

    .technicalInfo > .technicalLinks {
        flex-direction: column;
        gap: 0;
    }

    .technicalInfo > .technicalLinks > .technicalPages {
        margin: 10px 0;
        padding: 0;
    }

    .technicalInfo > .technicalLinks > .technicalPages > a {
        font-size: 17px;
        line-height: 27px;
    }

    .copyright {
        margin: 10px 0 0;
        font-size: 13px;
        line-height: 23px;
    }
}